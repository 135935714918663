import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <svg className="cmp-footer__icon cmp-footer__icon--linkedin" xmlns="http://www.w3.org/2000/svg" width="28" height="26" fill="#FFF">
      <title>LinkedIn</title>
  <path fillRule="nonzero" d="M10 8h5.535v2.837h.079C16.384 9.456 18.269 8 21.078 8 26.92 8 28 11.637 28 16.367V26h-5.769v-8.54c0-2.037-.042-4.657-3.001-4.657-3.005 0-3.463 2.218-3.463 4.509V26H10V8zM0 8h6v18H0V8zm6-5a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
    </svg>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      