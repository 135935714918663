import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <svg className="cmp-footer__icon cmp-footer__icon--twitter" xmlns="http://www.w3.org/2000/svg" width="32" height="26" fill="#FFF">
      <title>Twitter</title>
  <path fillRule="nonzero" d="M32 3.075a12.937 12.937 0 0 1-3.768 1.03 6.6 6.6 0 0 0 2.886-3.63c-1.269.75-2.674 1.3-4.168 1.594A6.564 6.564 0 0 0 22.157 0a6.562 6.562 0 0 0-6.562 6.563c0 .512.056 1.012.169 1.494A18.63 18.63 0 0 1 2.236 1.195a6.56 6.56 0 0 0-.887 3.3 6.557 6.557 0 0 0 2.918 5.463 6.563 6.563 0 0 1-2.974-.82v.082a6.564 6.564 0 0 0 5.268 6.436 6.572 6.572 0 0 1-2.968.112 6.587 6.587 0 0 0 6.13 4.563A13.167 13.167 0 0 1 0 23.051 18.562 18.562 0 0 0 10.067 26C22.14 26 28.745 15.994 28.745 7.32c0-.287-.006-.57-.02-.85A13.215 13.215 0 0 0 32 3.076v-.001z" />
    </svg>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      