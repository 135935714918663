import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <svg className="cmp-footer__icon cmp-footer__icon--dribbble" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#FFF">
      <title>Dribbble</title>
  <path fillRule="nonzero" d="M16 32C7.175 32 0 24.825 0 16S7.175 0 16 0s16 7.175 16 16-7.175 16-16 16zm13.494-13.812c-.469-.15-4.231-1.269-8.512-.581 1.788 4.912 2.512 8.912 2.656 9.744 3.063-2.075 5.25-5.356 5.856-9.163zM21.337 28.6c-.206-1.2-.994-5.375-2.913-10.363-.031.012-.063.019-.087.031-7.713 2.688-10.481 8.031-10.725 8.531a13.612 13.612 0 0 0 8.387 2.887c1.894 0 3.7-.387 5.338-1.087v.001zM5.844 25.156c.313-.531 4.063-6.738 11.106-9.019.175-.056.356-.113.538-.162a50.041 50.041 0 0 0-1.106-2.319C9.563 15.7 2.938 15.612 2.338 15.606c-.006.137-.006.275-.006.419 0 3.506 1.331 6.712 3.512 9.131zM2.625 13.219c.612.006 6.244.031 12.637-1.662a86.017 86.017 0 0 0-5.069-7.906C6.368 5.457 3.505 8.982 2.624 13.22l.001-.001zM12.8 2.731a73.225 73.225 0 0 1 5.1 8c4.863-1.819 6.919-4.588 7.163-4.938A13.598 13.598 0 0 0 16 2.349a14.22 14.22 0 0 0-3.2.381v.001zm13.781 4.65c-.288.388-2.581 3.325-7.631 5.388.319.65.625 1.313.906 1.981.1.238.2.469.294.706 4.55-.569 9.069.344 9.519.438a13.628 13.628 0 0 0-3.087-8.512l-.001-.001z" />
    </svg>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      