import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import HeaderBlock from "../../components/2019/header-block";
import ContentBlock from "../../components/2019/content-block";
import NumberBlock from "../../components/2019/number-block";
import Diagram4a from "../../components/2019/diagram-4a";
import Diagram4b from "../../components/2019/diagram-4b";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <HeaderBlock mdxType="HeaderBlock">
      <h3>{`The success of a design system can be determined by many factors, but three commonalities stand out.`}</h3>
    </HeaderBlock>
    <ContentBlock mdxType="ContentBlock">
      <p>{`The `}<strong parentName="p">{`49%`}</strong>{` of in-house team respondents who ranked their design system as successful follow three commonalities:`}</p>
      <ol>
        <li parentName="ol">{`Have a dedicated team to maintain the design system`}</li>
        <li parentName="ol">{`Integrate the design system into the developer workflow`}</li>
        <li parentName="ol">{`Source at least 50% of work product from the design system`}</li>
      </ol>
    </ContentBlock>
    <NumberBlock pointNumber="1" mdxType="NumberBlock">
      <h4>{`Have a dedicated team to maintain the design system.`}</h4>
    </NumberBlock>
    <ContentBlock mdxType="ContentBlock">
      <p><strong parentName="p">{`20 of 26`}</strong>{` in-house respondents with a successful design system answered an open-ended question that both designers and developers were assigned to support the design system in either a part-time or full-time capacity.`}</p>
      <p>{`More often than not, the list of team members was cross-functional, expanding beyond designers, user experience, and developers to include product owners, copywriters, and managers.`}</p>
    </ContentBlock>
    <NumberBlock pointNumber="2" mdxType="NumberBlock">
      <h4>{`Integrate the design system into the developer workflow.`}</h4>
    </NumberBlock>
    <ContentBlock mdxType="ContentBlock">
      <p>{`Of respondents with a successful design system, `}<strong parentName="p">{`79%`}</strong>{` integrated the design system into the developer's workflow or tooling. The following are how respondents with successful design system deliver it to the consumers of the design system:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`49%`}</strong>{` deliver their design system by an external codebase and consumed via package`}</li>
        <li parentName="ul"><strong parentName="li">{`15%`}</strong>{` deliver their design system by an external codebase and consumed by the main codebase’s pipeline`}</li>
        <li parentName="ul"><strong parentName="li">{`15%`}</strong>{` deliver the design system in the main codebase `}</li>
      </ul>
      <p>{`These delivery models  make the design system a way of life for developers.`}</p>
    </ContentBlock>
    <NumberBlock pointNumber="3" mdxType="NumberBlock">
      <h4>{`Source at least 50% of work product from the design system.`}</h4>
    </NumberBlock>
    <ContentBlock mdxType="ContentBlock">
      <p>{`Of responders who said they felt their design system was very successful or successful, `}<strong parentName="p">{`62%`}</strong>{` were sourcing 50% or more of their website from the design system. And of those who perceived their design system as very successful, `}<strong parentName="p">{`63%`}</strong>{` had over 75% of their web properties sourced from their design system.`}</p>
    </ContentBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      