import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import HeaderBlock from "../../components/2019/header-block";
import ContentBlock from "../../components/2019/content-block";
import LinkList from "../../components/2019/link-list";
import LinkItem from "../../components/2019/link-item";
import Form from "../../components/2019/form";
import SpecialLink from "../../components/2019/callout-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <HeaderBlock mdxType="HeaderBlock">
      <h3>{`Having a design system is an investment in being consistent that pays off with efficiency gains.`}</h3>
    </HeaderBlock>
    <ContentBlock mdxType="ContentBlock">
      <p>{`As we reflect on the results of this year’s survey, we feel the experiences of in-house teams and agencies shared a common theme: the greatest values of design systems are consistency and efficiency. These two benefits are related. Consistency is not just good for user experience or for design cohesiveness across a brand or brands, but it begets efficiency because there is no need to reinvent the wheel—either in concept or code.`}</p>
      <p>{`Efficiency suggests speed, and speed can result in time and cost savings. This is the ultimate return on investment for an organization that adopts a design system—freeing up designers and developers to do more innovative work and freeing up budget to pursue organizational growth, product innovations, or other goals.`}</p>
    </ContentBlock>
    <HeaderBlock mdxType="HeaderBlock">
      <h3>{`Read more articles on building, using, and maintaining a design system from the experts at Sparkbox.`}</h3>
    </HeaderBlock>
    <ContentBlock mdxType="ContentBlock">
      <p><a parentName="p" {...{
          "href": "https://sparkbox.com",
          "title": "Sparkbox Website Homepage"
        }}>{`Sparkbox`}</a>{` has become an expert in design systems and hopes to continue helping others through `}<a parentName="p" {...{
          "href": "https://sparkbox.com/foundry/category/design_systems",
          "title": "Sparkbox Website Foundry Page"
        }}>{`writing articles about design systems.`}</a></p>
      <LinkList mdxType="LinkList">
  <LinkItem title="Your Design System Team Is Part of the&nbsp;Product" href="https://sparkbox.com/foundry/design_systems_teams_are_part_of_the_product" author="Drew Clemens" authorUrl="https://sparkbox.com/foundry/author/drew_clemens" mdxType="LinkItem" />
  <LinkItem title="Engaging Subscribers in Your Design System from FullStack&nbsp;NYC" href="https://sparkbox.com/foundry/making_design_systems_engaging_session_from_fullstack_NYC" author="Catherine Meade" authorUrl="https://sparkbox.com/foundry/author/catherine_meade" mdxType="LinkItem" />
  <LinkItem title="How to Get Started with a Design&nbsp;System" href="https://sparkbox.com/foundry/design_system_planning_project_discovery_phase" author="Emily Gray" authorUrl="https://sparkbox.com/foundry/author/emily_gray" mdxType="LinkItem" />
  <LinkItem title="The Top Design System Tools from Our 2019 Survey and Why “Top” Might Be an&nbsp;Overstatement" href="https://sparkbox.com/foundry/comparing_design_system_tools" author="Julie Young" authorUrl="https://sparkbox.com/foundry/author/julie_young" mdxType="LinkItem" />
  <LinkItem title="Rationale for Automated Testing and Continuous Integration for Design Systems" href="https://sparkbox.com/foundry/automated_testing_for_design_systems" author="Adam Simpson" authorUrl="https://sparkbox.com/foundry/author/adam_simpson" mdxType="LinkItem" />
  <LinkItem title="Visual Regression Testing in Design Systems" href="https://sparkbox.com/foundry/design_system_visual_regression_testing" author="Patrick Fulton" authorUrl="https://sparkbox.com/foundry/author/patrick_fulton" mdxType="LinkItem" />
  <LinkItem title="Accessibility Testing Design Systems" href="https://sparkbox.com/foundry/design_system_accessibility_testing" author="Rob Tarr" authorUrl="https://sparkbox.com/foundry/author/rob_tarr" mdxType="LinkItem" />
  <LinkItem title="Testing the Components and Behaviors of Your Design System" href="https://sparkbox.com/foundry/design_system_component_testing_unit_testing_behavior_testing" author="Jon Oliver" authorUrl="https://sparkbox.com/foundry/author/jon_oliver" mdxType="LinkItem" />
  <LinkItem title="What Do We Value Most About Design Systems?" href="https://sparkbox.com/foundry/design_systems_benefits_consistency_efficiency_prototyping" author="Katy Bowman" authorUrl="https://sparkbox.com/foundry/author/katy_bowman" mdxType="LinkItem" />
  <LinkItem title="Auditing as a First Step to Design Systems Planning" href="https://sparkbox.com/foundry/design_audit_for_design_system_planning" author="Heather Taylor" authorUrl="https://sparkbox.com/foundry/author/heather_taylor" mdxType="LinkItem" />
  <LinkItem title="Designing for Design Systems" href="https://sparkbox.com/foundry/designing_for_design_systems" author="Ethan Muller" authorUrl="https://sparkbox.com/foundry/author/ethan_muller" mdxType="LinkItem" />
      </LinkList>
      <p>{`Are you looking for help building, adjusting, or maintaining your design system? `}<a parentName="p" {...{
          "href": "https://sparkbox.com/contact",
          "title": "Sparkbox Website Work With Us Page"
        }}>{`Reach out to us for more information on how we can help!`}</a></p>
      <p>{`Or are you interested in the full data set of this survey to run your own analysis?`}</p>
      <SpecialLink href="https://www.dropbox.com/s/8jkacbyvs1ginfi/2019%20Design%20Systems%20Survey%20Shared%20Results.xlsx?dl=0" mdxType="SpecialLink">Download the CSV file on Dropbox.</SpecialLink>
    </ContentBlock>
    <HeaderBlock mdxType="HeaderBlock">
      <h3>{`Stay Informed with Sparkbox Newsletters.`}</h3>
    </HeaderBlock>
    <ContentBlock mdxType="ContentBlock">
      <p>{`The 2019 Design Systems Survey is just the start. Sign up and receive one to two emails each month with more information on design systems and other web design and development content right to your inbox.`}</p>
      <Form mdxType="Form" />
    </ContentBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      