import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SpecialLink from "../../components/2019/callout-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className="util-margin-btm4">
  <SpecialLink href="https://designsystemssurvey.seesparkbox.com/" mdxType="SpecialLink">Check out the results from the latest Design Systems Survey</SpecialLink>
    </div>
    <p>{`The 2019 Design Systems Survey is intended to illuminate how design systems are created, used, and maintained. Design systems have the ability to solve real-life problems by establishing consistency in design and code, promoting efficiency when creating products, communicating usage guidance, encouraging reuse instead of rework, increasing the accessibility and usability of digital properties, and so much more.`}</p>
    <p>{`For the `}<a parentName="p" {...{
        "href": "https://designsystemssurvey.seesparkbox.com/2018/",
        "title": "2018 Design System Survey"
      }}>{`second year in a row`}</a>{`, `}<a parentName="p" {...{
        "href": "https://sparkbox.com/",
        "title": "Sparkbox Website"
      }}>{`Sparkbox`}</a>{`, a web design and development firm, has conducted this survey to learn more about both the challenges and benefits of building and using design systems. By analyzing the reflections of in-house teams who use a design system and agencies who’ve helped organizations build new or maintain existing design systems, we look to inform those who want to build or improve their own.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      