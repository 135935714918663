import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import HeaderBlock from "../../components/2019/header-block";
import ContentBlock from "../../components/2019/content-block";
import DiagramRespondents from "../../components/2019/diagram-respondents";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ContentBlock mdxType="ContentBlock">
      <p>{`This survey had `}<strong parentName="p">{`256`}</strong>{` responses comprised of two respondent types:`}</p>
      <ol>
        <li parentName="ol">{`In-house teams who use a design system for their organization`}</li>
        <li parentName="ol">{`Agencies who build or contribute to design systems for clients`}</li>
      </ol>
      <p>{`The two respondent types were asked different sets of questions with some common questions. As each audience brings different expertise to the survey, we will be acknowledging both types of responses.`}</p>
      <p>{`This survey was shared across social media, Slack channels, at conferences attended by Sparkbox, and was emailed directly to web design and development professionals.`}</p>
    </ContentBlock>
    <HeaderBlock mdxType="HeaderBlock">
      <h3>{`The 2019 Design Systems Survey received 148 responses from in-house teams who use a design system for their organizations.`}</h3>
    </HeaderBlock>
    <ContentBlock mdxType="ContentBlock">
      <p>{`These `}<strong parentName="p">{`148`}</strong>{` in-house team respondents came from various company sizes ranging from a couple of team members to over 10,000 employees. Industries include e-commerce, finance, healthcare, advertising and marketing, education, and many others.`}</p>
      <p>{`The in-house team survey respondents came from various job disciplines. `}<strong parentName="p">{`49%`}</strong>{` are designers or user experience professionals, `}<strong parentName="p">{`39%`}</strong>{` are developers, `}<strong parentName="p">{`8%`}</strong>{` are managers, and `}<strong parentName="p">{`4%`}</strong>{` are from other job disciplines.`}</p>
    </ContentBlock>
    <DiagramRespondents mdxType="DiagramRespondents" />
    <ContentBlock mdxType="ContentBlock">
      <p><strong parentName="p">{`92%`}</strong>{` of the in-house team respondents stated that their organization is actively maintaining its design system. When asked who is primarily responsible for the design system, `}<strong parentName="p">{`50%`}</strong>{` said designers or user experience professionals, `}<strong parentName="p">{`30%`}</strong>{` said developers, `}<strong parentName="p">{`11%`}</strong>{` said a cross-functional team, `}<strong parentName="p">{`4%`}</strong>{` said project managers, and `}<strong parentName="p">{`5%`}</strong>{` said others.`}</p>
    </ContentBlock>
    <HeaderBlock mdxType="HeaderBlock">
      <h3>{`The 2019 Design Systems Survey received 108 responses from agencies who build or contribute to design systems for clients.`}</h3>
    </HeaderBlock>
    <ContentBlock mdxType="ContentBlock">
      <p>{`Of these `}<strong parentName="p">{`108`}</strong>{` agency respondents, `}<strong parentName="p">{`81%`}</strong>{` work at firms with `}<strong parentName="p">{`200`}</strong>{` or fewer employees. These agency respondents include people from traditional advertising or digital-only agencies, IT professional services firms, small studios, and freelancers.`}</p>
      <p>{`The agency survey respondents came from various job disciplines. `}<strong parentName="p">{`51%`}</strong>{` are designers or user experience professionals, `}<strong parentName="p">{`42%`}</strong>{` are developers, and `}<strong parentName="p">{`7%`}</strong>{` are in management disciplines.`}</p>
    </ContentBlock>
    <HeaderBlock mdxType="HeaderBlock">
      <h3>{`Most respondents are designers, developers, or user experience professionals.`}</h3>
    </HeaderBlock>
    <ContentBlock mdxType="ContentBlock">
      <p>{`In total, this survey captured `}<strong parentName="p">{`92%`}</strong>{` of responses from individuals in the design (`}<strong parentName="p">{`38%`}</strong>{`), user experience (`}<strong parentName="p">{`13%`}</strong>{`), or development (`}<strong parentName="p">{`41%`}</strong>{`) fields. Only `}<strong parentName="p">{`8%`}</strong>{` were in product/project management, marketing, strategy, management or other disciplines combined.`}</p>
    </ContentBlock>
    <HeaderBlock mdxType="HeaderBlock">
      <h3>{`Respondents are primarily self-taught about design systems.`}</h3>
    </HeaderBlock>
    <ContentBlock mdxType="ContentBlock">
      <p>{`Over `}<strong parentName="p">{`70%`}</strong>{` of the in-house respondents and `}<strong parentName="p">{`65%`}</strong>{` of agency respondents said they first learned about design systems by reading articles about design systems. And `}<strong parentName="p">{`77%`}</strong>{` of in-house respondents and `}<strong parentName="p">{`70%`}</strong>{` of agency respondents stated reading articles about design systems is the most helpful resource for understanding design systems.`}</p>
    </ContentBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      