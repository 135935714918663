import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <svg className="cmp-footer__icon cmp-footer__icon--youtube" xmlns="http://www.w3.org/2000/svg" width="32" height="23" fill="#FFF">
      <title>YouTube</title>
  <path fillRule="nonzero" d="M31.675 4.963s-.313-2.255-1.275-3.245C29.181.415 27.819.408 27.194.332 22.719 0 16 0 16 0h-.012S9.269 0 4.794.332C4.169.41 2.807.415 1.588 1.718.625 2.708.319 4.963.319 4.963S0 7.607 0 10.257v2.479c0 2.643.319 5.294.319 5.294s.313 2.255 1.269 3.245c1.219 1.303 2.819 1.258 3.531 1.399C7.682 22.924 16 23 16 23s6.725-.012 11.2-.338c.625-.077 1.988-.083 3.206-1.386.962-.99 1.275-3.245 1.275-3.245S32 15.387 32 12.737v-2.479c-.006-2.644-.325-5.294-.325-5.294v-.001zM12.688 15.744V6.553l8.644 4.612-8.644 4.58z" />
    </svg>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      