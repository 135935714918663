import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="cmp-dodecahedron">
  <div className="cmp-dodecahedron__shape">
    <div className="cmp-dodecahedron__top">
      <div className="cmp-dodecahedron__side cmp-dodecahedron__side--1"></div>
      <div className="cmp-dodecahedron__side cmp-dodecahedron__side--2"></div>
      <div className="cmp-dodecahedron__side cmp-dodecahedron__side--3"></div>
      <div className="cmp-dodecahedron__side cmp-dodecahedron__side--4"></div>
      <div className="cmp-dodecahedron__side cmp-dodecahedron__side--5"></div>
      <div className="cmp-dodecahedron__side cmp-dodecahedron__side--6"></div>
    </div>
    <div className="cmp-dodecahedron__bottom">
      <div className="cmp-dodecahedron__side cmp-dodecahedron__side--1"></div>
      <div className="cmp-dodecahedron__side cmp-dodecahedron__side--2"></div>
      <div className="cmp-dodecahedron__side cmp-dodecahedron__side--3"></div>
      <div className="cmp-dodecahedron__side cmp-dodecahedron__side--4"></div>
      <div className="cmp-dodecahedron__side cmp-dodecahedron__side--5"></div>
      <div className="cmp-dodecahedron__side cmp-dodecahedron__side--6"></div>
    </div>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      