import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <svg className="cmp-footer__icon cmp-footer__icon--facebook" xmlns="http://www.w3.org/2000/svg" width="16" height="32" fill="#FFF">
      <title>Facebook</title>
  <path fillRule="nonzero" d="M11 6h5V0h-5C7.14 0 4 3.14 4 7v3H0v6h4v16h6V16h5l1-6h-6V7c0-.542.458-1 1-1z" />
    </svg>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      