import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <svg className="cmp-footer__icon cmp-footer__icon--github" xmlns="http://www.w3.org/2000/svg" width="32" height="31" fill="#FFF">
      <title>Github</title>
  <path fillRule="nonzero" d="M16 0C7.164 0 0 7.115 0 15.893c0 7.021 4.585 12.979 10.942 15.08.8.147 1.094-.345 1.094-.765 0-.379-.015-1.631-.022-2.96-4.452.962-5.391-1.875-5.391-1.875-.728-1.836-1.776-2.325-1.776-2.325-1.452-.986.11-.966.11-.966 1.606.112 2.452 1.638 2.452 1.638 1.427 2.43 3.743 1.727 4.656 1.32.143-1.026.558-1.728 1.016-2.125-3.554-.401-7.29-1.765-7.29-7.854 0-1.735.625-3.153 1.649-4.266-.166-.4-.714-2.016.155-4.206 0 0 1.344-.427 4.401 1.63a15.45 15.45 0 0 1 4.005-.536c1.359.006 2.729.183 4.008.535 3.054-2.056 4.395-1.629 4.395-1.629.871 2.19.323 3.806.157 4.206 1.026 1.113 1.647 2.53 1.647 4.266 0 6.104-3.743 7.448-7.306 7.842.574.494 1.085 1.46 1.085 2.943 0 2.127-.019 3.838-.019 4.362 0 .423.288.919 1.099.763C27.421 28.867 32 22.912 32 15.893 32 7.115 24.836 0 16 0z" />
    </svg>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      